import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import {
  addCustomBrokersItem,
  removeCustomBrokersItem,
  setLicenseKey,
  removeLicenseKey,
  setClientPRO,
  setLicenseKeyError, setClientFree,
} from "./actions";

export default combineReducers({
  customBrokersList: handleActions(
    {
      [addCustomBrokersItem]: (state, action) => {
        let maxId = 0;
        if (state.length) {
          state.forEach(({ id }) => {
            if (id > maxId) maxId = id;
          });
        }
        return [...state, { ...action.payload, id: maxId + 1 }];
      },
      [removeCustomBrokersItem]: (state, action) => [
        ...state.filter(({ id }) => id !== action.payload),
      ],
    },
    []
  ),
  licenseKey: handleActions(
    {
      [setLicenseKey]: (state, action) => action.payload,
      [removeLicenseKey]: () => null,
    },
    null
  ),
  licenseKeyError: handleActions(
    {
      [setLicenseKeyError]: (state, action) => action.payload,
    },
    false
  ),
  clientRate: handleActions({
    [setClientPRO]: () => 'PRO',
    [setClientFree]: () => 'Free',
    [removeLicenseKey]: () => null,
  }, null),
  isAuth: handleActions({
    [setClientPRO]: () => true,
    [setClientFree]: () => true,
    [removeLicenseKey]: () => false,
  }, false)
});
