import isElectron from "is-electron";

import config from "./config";
import store from "./store";
import { getLicenseKey } from "./modules/mainSettings";

export const openLink = ({ url, title }, newTabCallback = null) => {
  if (url.indexOf("defbrowser::") === 0) {
    const authToken = getLicenseKey(store.getState());
    url = url.replace("defbrowser::", "");

    if (isElectron()) {
      if (url.startsWith("mailto:")) {
        window.electron.shell.openExternal(url);
        return;
      }
      window.electron.shell.openExternal(`${config.apiHost}/proxy/redirect?url=${url}&auth=${authToken}`);
    } else {
      if (authToken) {
        window.open(`${config.apiHost}/proxy/redirect?url=${url}&auth=${authToken}`, "_blank");
        return;
      }
      window.open(`https://vfxalert.com/login`, "_blank");
    }
    return;
  }

  if (newTabCallback) {
    newTabCallback({ url, title });
  }
};

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  function isObject(object) {
    return object != null && typeof object === "object";
  }

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
};
