import { fetchInitialSettings as fetchAction, successInitialSettings, errorInitialSettings } from "./actions";
import { receiveInitialSignals } from "../signals";
import { receiveInitialIndicators } from "../indicators";
import { requestInitialSettings } from "../api";
import { getLicenseKey } from "../mainSettings";
import { resetBrokerIntegrationList, updateTms } from "../client";
import { connectSocket } from "../socket";
import { initDashboard } from "../dashboard";

export const fetchInitialSettings = () => async (dispatch, getState) => {
  dispatch(fetchAction());
  const lkey = getLicenseKey(getState());
  try {
    const { data } = await requestInitialSettings({ lkey });
    dispatch(updateTms(data.tms));
    dispatch(successInitialSettings(data));
    dispatch(receiveInitialSignals([...data.signals]));
    dispatch(receiveInitialIndicators([...data.indicators]));
    dispatch(initDashboard([...data?.env_settings?.free_account_assets]));
    dispatch(resetBrokerIntegrationList());

    if (process.env.NODE_ENV === "production" && data?.env_settings?.debug === true) {
      window.Sentry.init({
        dsn: "https://efbfb30eeb0643cf8337eeaf30f96ae4@o348725.ingest.sentry.io/5236143",
      });
      console.log("debug tool connected");
    }

    const { host, channel } = data["ws-config"];
    dispatch(connectSocket({ host, channel }));
  } catch (e) {
    console.error(e);
    dispatch(errorInitialSettings());
  }
};
