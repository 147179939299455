import { FC } from "react";
import styled from "styled-components";
import FormButton from "../base/form/Button";
import { ReactComponent as GreenManSvg } from "./assets/green-man.svg";
import { ReactComponent as RefreshSvg } from "./assets/refresh.svg";

interface OnlyForProViewProps {
  onGoPro: () => void;
  onReload: () => void;
}

export const OnlyForProView: FC<OnlyForProViewProps> = ({ onGoPro, onReload }) => {
  return (
    <Wrapper>
      <GreenMan />
      <Text>Web version available only for PRO.</Text>
      <ButtonsWrapper>
        <Refresh width={20} height={20} onClick={onReload} />
        <Button onClick={onGoPro}>Buy PRO</Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const GreenMan = styled(GreenManSvg)`
  margin-bottom: 20px;
`;

const Text = styled.p`
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 140px;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled(FormButton)`
  padding: 10px 20px;
`;

const Refresh = styled(RefreshSvg)`
  cursor: pointer;
`;
