import {
  setActiveSignalsType as setActiveSignalsTypeState,
  newTab as newTabState,
  setActiveTab,
  updateTms as updateTmsState,
  updateTmsShift,
  setActiveBroker,
  setShowConnectBrokerPage,
} from "./actions";
import { getActiveBroker, getActiveTabId, getTabs } from "./selector";
import { getBrokersList } from "../initial";

let tmsShiftInterval;

export const setActiveSignalsType = (payload) => (dispath, getState) => {
  dispath(setActiveSignalsTypeState(payload));
};

export const newTab = (payload) => (dispatch, getState) => {
  let tabIsSet = getTabs(getState()).find(({ url }) => payload.url === url);
  if (!tabIsSet) {
    dispatch(newTabState(payload));
    tabIsSet = getTabs(getState()).find(({ url }) => payload.url === url);
  }

  dispatch(setActiveTab(tabIsSet.id));
};

export const updateTms = (payload) => (dispath, getState) => {
  clearInterval(tmsShiftInterval);
  tmsShiftInterval = setInterval(() => {
    dispath(updateTmsShift());
  }, 1000);

  dispath(updateTmsState(payload));
};

export const setShowConnectBrokersThunk = (payload) => (dispatch, getState) => {
  if (payload) {
    dispatch(setShowConnectBrokerPage(true));
    return;
  }

  const state = getState();
  const brokerList = getBrokersList(state);

  dispatch(setShowConnectBrokerPage(false));

  // if (brokerList) {
  //   const defaultBroker = brokerList?.find((broker) => broker.code === "pocketoption") || brokerList[0];
  //
  //   if (defaultBroker) {
  //     dispatch(setActiveBroker(defaultBroker.code));
  //     dispatch(setActiveTab("brokerTab"));
  //   }
  // }
};

export const openBrokerTabThunk = (brokerCode) => (dispatch, getState) => {
  const state = getState();
  const tabs = getTabs(state);
  const brokerTab = tabs.find((broker) => broker.code === brokerCode);

  if (!brokerTab) return;

  dispatch(setActiveTab(brokerTab.id));
};

export const openActiveBrokerTabThunk = () => (dispatch, getState) => {
  const state = getState();
  const tabs = getTabs(state);
  const activeBroker = getActiveBroker(state);
  const activeBrokerTab = tabs.find((tab) => tab.brokerCode === activeBroker);

  dispatch(setActiveTab(activeBrokerTab.id));
};
