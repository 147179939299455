import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchInitialSettings, isInitialLoaded, toggleInitialPopup } from "./modules/initial";
import { isClientPRO, setLicenseKey } from "./modules/mainSettings";
import { invalidateStorage, getStorageVersion } from "./modules/storageVersion";
import Layout from "./Components/Layout";
import isElectron from "is-electron";
import Cookies from "js-cookie";
import params from "./config";
import OnlyForPro from "./Components/OnlyForPro";
import Login from "./Components/Login";

const { actualStorageVersion } = params;

class App extends Component {
  state = {
    isAvailable: true,
  };

  componentWillMount() {
    const { dispatch, storageVersion } = this.props;
    if (storageVersion !== actualStorageVersion) {
      dispatch(invalidateStorage());
      return;
    }
    if (isElectron()) {
      dispatch(toggleInitialPopup(true));
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    let cookieLkey = Cookies.get("app_token");

    if (!cookieLkey) {
      const searchParams = new URLSearchParams(window.location.search);
      const appToken = searchParams.get("app_token");
      cookieLkey = appToken;
    }

    if (isElectron() || !cookieLkey) {
      this.setState({ isAvailable: false });
      return;
    }

    dispatch(setLicenseKey({ lkey: cookieLkey }));

    dispatch(fetchInitialSettings());
  }

  render() {
    const { isAvailable } = this.state;
    const { isPro } = this.props;

    if (!isElectron() && !isAvailable) {
      return <Login />;
    }

    if (!isPro || !isAvailable) {
      return <OnlyForPro />;
    }

    return (
      <>
        <Layout />
      </>
    );
  }
}

export default connect((state) => ({
  isInitialLoaded: isInitialLoaded(state),
  storageVersion: getStorageVersion(state),
  isPro: isClientPRO(state),
}))(App);
