import axios from "axios";
import params from "../config";
import packageJson from "../../package.json";
import isElectron from "is-electron";

const { apiHost, appEnvType } = params;

const baseRequestData = {
  version: packageJson.version,
  build: packageJson.hash,
  platform: isElectron() ? "desktop" : "web",
  env_type: appEnvType,
};

export const requestInitialSettings = ({ lkey, login, password }) => {
  let data = { ...baseRequestData };

  if (login && login.length > 0) {
    data = { ...data, login, pwd: password };
  } else {
    data = { ...data, lkey };
  }

  return axios({
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
    url: `${apiHost}/proxy/initial`,
    data,
  });
};

export const sendBrokerRegistrationInfo = (data, vfxUserId) => {
  const reqAppData = { ...baseRequestData };

  return axios({
    method: "POST",
    url: `${apiHost}/proxy/broker-registrations`,
    data: {
      ...reqAppData,
      vfxUserId,
      brokers: data,
    },
  });
};
