import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getLayoutSettings, getDashboardScale, setDashboardScale } from "../../modules/client";
import LeftPanel from "../LeftPanel";
import Menu from "../Menu";
import { isAuth } from "../../modules/mainSettings";
import "./index.css";
import "react-reflex/styles.css";

const defaultLayout = (isAuth) => ({
  showTypeSettings: false,
});

class Layout extends Component {
  static propTypes = {
    leftPanel: PropTypes.bool.isRequired,
    dashboardPanel: PropTypes.bool.isRequired,
  };

  state = {
    ...defaultLayout(this.props.isAuth),
    isResizing: false,
  };

  menuButton = (stateIndex) => {
    const value = this.state[stateIndex];
    return {
      isActive: value,
      handleClick: ((stateIndex) => () => {
        const value = this.state[stateIndex];
        this.setState({
          ...defaultLayout(this.props.isAuth),
          [stateIndex]: !value,
        });
      })(stateIndex),
    };
  };

  render() {
    const { leftPanel } = this.props;

    return (
      <>
        <TitleBarSection />
        <section id="main">
          <Menu typeSettings={this.menuButton("showTypeSettings")} />
          <LeftPanel
            {...{
              showSignalsList: leftPanel,
            }}
            typeSettings={this.menuButton("showTypeSettings")}
          />
          <div className="graph_wrap" />
        </section>
      </>
    );
  }
}

const TitleBarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: white;
  font-weight: bold;
  height: 27px;
  width: 100%;
  background-color: #3a4255;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

export default connect(
  (state) => ({
    ...getLayoutSettings(state),
    isAuth: isAuth(state),
    scale: getDashboardScale(state),
  }),
  { setDashboardScale }
)(Layout);
