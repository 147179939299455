import { useEffect, useState } from "react";
import View from "./View";
import { openLink } from "../../../../utils";

const Pro1HourOfferAlert = ({ registerTime, currentTime, link }) => {
  const [minutesRemain, setMinutesRemain] = useState("00");
  const [secondsRemain, setSecondsRemain] = useState("00");

  useEffect(() => {
    const diff = new Date(3600000 - (currentTime - registerTime * 1000)).toISOString().substr(14, 5).split(":");
    setMinutesRemain(diff[0]);
    setSecondsRemain(diff[1]);
  }, [currentTime]);

  return <View {...{ minutesRemain, secondsRemain }} handleClick={() => openLink({ url: `${link}?utm_source=promo_app` }, () => {})} />;
};

export default Pro1HourOfferAlert;
