import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { openLink } from "../../../utils";

import { ReactComponent as SuccessIcon } from "./assets/success.svg";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import { ReactComponent as ErrorIcon } from "./assets/error.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

const bgColorMap = {
  success: "#5cbb2d",
  info: "#407bff",
  warning: "#e2a22d",
  error: "#ee2b2b",
};

const iconMap = {
  success: <SuccessIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

class Notification extends React.Component {
  state = {
    notification: null,
  };

  componentDidMount() {
    if (!this.props.notification) return;

    this.setState({ notification: this.props.notification });
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.notification === undefined) return true;

    this.setState({ notification: nextProps.notification });

    return true;
  }

  openNotification = ({ type, title, body }) => {
    this.setState({
      notification: {
        type,
        title,
        body,
      },
    });
  };

  closeNotification = () => {
    const { onClose } = this.props;
    this.setState({ notification: null });
    if (onClose) onClose();
  };

  handleLinkClicked = (e) => {
    e.preventDefault();
    const url = e.target.href;

    if (!url) return;

    openLink({ url });

    this.closeNotification();
  };

  render() {
    const { notification } = this.state;

    if (!notification) return null;

    const { type, title, body } = notification;

    return (
      <Container color={bgColorMap[type]}>
        <IconWrapper>{iconMap[type]}</IconWrapper>
        <Content>
          <Title>{title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: body }} onClick={this.handleLinkClicked} />
        </Content>
        <CloseButton onClick={this.closeNotification}>
          <CloseIcon />
        </CloseButton>
      </Container>
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.shape({
    type: PropTypes.oneOf(["success", "info", "warning", "error"]).isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func,
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  background: ${({ color }) => color};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 4px;
  height: 35px;
  width: 35px;
  margin-right: 15px;
`;

const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.p`
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Body = styled.span`
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  a {
    text-decoration: underline;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
`;

const CloseButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export default Notification;
