import { setLicenseKey as setLicenseKeyState, setClientPRO, setLicenseKeyError, setClientFree, removeLicenseKey } from "./actions";

import { requestInitialSettings } from "../api";
import { setInitialLoading, successInitialSettings } from "../initial";
import { receiveInitialSignals } from "../signals";
import { receiveInitialIndicators } from "../indicators";
import { updateTms } from "../client";
import { initDashboard } from "../dashboard";

export const setLicenseKey = (payload) => async (dispatch, getState) => {
  dispatch(setInitialLoading(true));
  dispatch(setLicenseKeyError(false));
  let setError = true;
  try {
    const { data } = await requestInitialSettings({ ...payload });

    const isValid = !!data?.account_info?.user_id;
    console.log("isValid", isValid);

    dispatch(updateTms(data.tms));
    dispatch(receiveInitialSignals([...data.signals]));
    dispatch(receiveInitialIndicators([...data.indicators]));
    dispatch(initDashboard([...data?.env_settings?.free_account_assets]));
    dispatch(setInitialLoading(false));

    if (isValid) {
      setError = false;
      dispatch(successInitialSettings(data));
      dispatch(setLicenseKeyState(data.lkey));

      if (data.account_info.license.toLowerCase() === "pro") {
        dispatch(setClientPRO());
      } else {
        dispatch(setClientFree());
      }
    }
  } catch (e) {
    console.log("Auth error");
  }

  if (setError) {
    dispatch(setLicenseKeyError(true));
    dispatch(setInitialLoading(false));
    setTimeout(() => {
      dispatch(setLicenseKeyError(false));
    }, 5000);
  }
};

export const removeLicenseKeyThunk = () => (dispatch) => {
  window.electron?.appLogout();
  dispatch(removeLicenseKey());
};
