import { FC } from "react";
import styled from "styled-components";
import Button from "../base/form/Button";

const Login: FC = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Wrapper>
      <Content>
        <p>You are not logged in.</p>
        <ButtonGroup>
          <a href={"https://vfxalert.com/login"} target={"_blank"}>
            <StyledButton>Login</StyledButton>
          </a>
          <StyledButton onClick={handleReload}>Reload</StyledButton>
        </ButtonGroup>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 30px;
  width: 350px;
  justify-content: space-around;
`;

const StyledButton = styled(Button)`
  width: 150px;
  padding: 10px 15px;
`;

export default Login;
