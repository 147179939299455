import { FC } from "react";

import { OnlyForProView as View } from "./View";
import { openLink } from "../../utils";

interface OnlyForProProps {}

const OnlyForPro: FC<OnlyForProProps> = () => {
  const handleGoPro = () => {
    openLink({ url: "defbrowser::https://vfxalert.com/store", title: "" });
  };

  const handleReload = () => {
    window.location.reload();
  };

  return <View onGoPro={handleGoPro} onReload={handleReload} />;
};

export default OnlyForPro;
