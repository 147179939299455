import React from "react";
import params from "../../config";

import MenuItem from "./MenuItem";

import { ReactComponent as QuestionSvg } from "./assets/question.svg";
import { ReactComponent as TelegramSvg } from "./assets/telegram.svg";

const View = ({ brokerSettings, typeSettings, layoutSettings, versionInfo, appSettings, openLink, links, tutorial, telegramMessages }) => {
  return (
    <div className="menu">
      <div className="menu_box">
        {/* <!-- Верхние иконки в меню --> */}
        {/* <!-- В блоке с классом menu_item изначально выводится иконка в пассивном состоянии. Если к картинке добавляется класс active, она выглядит активной. --> */}
        <div className="menu_item" onClick={typeSettings.handleClick}>
          <div className={`menu_item_icon bar_chart ${typeSettings.isActive ? "active" : ""}`}></div>
        </div>
        {/* <!-- Верхние иконки в меню конец --> */}
      </div>
      <div className="menu_box">{/* <!-- Нижние иконки в меню конец --> */}</div>
    </div>
  );
};

export default View;
