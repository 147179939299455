import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import View from "./View";
import { getLinks } from "../../modules/initial";
import { newTab } from "../../modules/client";
import { openLink } from "../../utils";

class Menu extends Component {
  static propTypes = {
    typeSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  };

  openLink = (data) => {
    openLink(data, (data) => {
      this.props.newTab(data);
    });
  };

  render() {
    return <View {...this.props} openLink={this.openLink} />;
  }
}

export default connect(
  (state) => ({
    links: getLinks(state),
  }),
  { newTab }
)(Menu);
