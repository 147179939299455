import styled, { css } from "styled-components";
import { ReactComponent as GraphIcon } from "./assets/graph-icon.svg";
import { ReactComponent as PieIcon } from "./assets/pie-icon.svg";
import { ReactComponent as ArrowUp } from "./assets/arrow-up.svg";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import signal0 from "./assets/signal-0.svg";
import signal1 from "./assets/signal-1.svg";
import signal2 from "./assets/signal-2.svg";
import signal3 from "./assets/signal-3.svg";
import signal4 from "./assets/signal-4.svg";
import signal5 from "./assets/signal-5.svg";
import signalBlocked from "./assets/signal-blocked.svg";
import ClassicVariants from "./ClassicVariants";
import BinaryVariant from "./BinaryVariant";
import params from "../../../../config";
import Tooltip from "../../../base/Tooltip";

const ContentVariant = params.appEnvType === "binary" ? BinaryVariant : ClassicVariants;
const buttonSize = "19px";

const powerIcons = {
  0: signal0,
  1: signal1,
  2: signal2,
  3: signal3,
  4: signal4,
  5: signal5,
  blocked: signalBlocked,
};

const View = (props) => {
  const {
    showFull,
    power,
    symbol,
    hm,
    onOpenPosition,
    onOpenChartsClicked,
    signal,
    expiration,
    onSetSymbolClicked,
    activeBroker,
    isStatic, // для отображения в окне настроек Signal View
    availableBrokerIntegrationList,
    market_id,
  } = props;

  const showAdditionalButtons = activeBroker && availableBrokerIntegrationList.includes(activeBroker) && market_id === 0;

  return (
    <Container>
      <DataContainer>
        <SymbolPowerContainer>
          <SymbolContainer>
            <SymbolLabel>{symbol}</SymbolLabel>
          </SymbolContainer>
          <PowerContainer {...{ showFull, power }} />
        </SymbolPowerContainer>
        <ContentVariant {...props} />
      </DataContainer>
      <HeatMapContainer>
        {["m1", "m5", "m15", "m30", "h1", "h4"].map((item) => (
          <HeatMapItem key={item} value={hm[item]} disabled={!showFull} />
        ))}
      </HeatMapContainer>
    </Container>
  );
};

export default View;

const gradientColor = (val) => {
  const colors = {
    5: "#49d147",
    4: "#39f711",
    3: "#64f711",
    2: "#99f711",
    1: "#bdf725",
    0: "#e0f465",
    "-0": "#f2df5e",
    "-1": "#f2b34c",
    "-2": "#f1904a",
    "-3": "#f16139",
    "-4": "#ff4a39",
    "-5": "#f1000e",
  };

  const div = val - 50;
  const perc = (100 * div) / 50;
  const index = Math.floor(perc / 10);
  let rez = index.toString();

  if (index === 0 && div < 0) rez = "-0";
  if (index > 5) rez = "5";
  if (index < -5) rez = "-5";

  return colors[`${rez}`];
};

const Container = styled.div`
  background: #1e2432;
  border-radius: 3px;
  padding: 8px 0 2px;
  margin: 0 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const HeatMapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 3px 1px;
`;
const HeatMapItem = styled.div`
  width: 32.02px;
  height: 2px;
  border-radius: 2px;
  margin: 0 2px;
  opacity: 0.9;
  ${(props) =>
    css`
      background-color: ${props.disabled ? "#91949B" : gradientColor(props.value)};
    `}
`;
const DataContainer = styled.div`
  margin: 0 12px 0 12px;
`;
const BaseIconButton = (isStatic) => css`
  background-color: rgba(120, 120, 120, 0.3);
  padding: 1px;
  cursor: pointer;
  opacity: 0;
  margin-right: 1px;

  ${Container}:hover & {
    opacity: 1;
  }

  &:hover {
    background-color: rgba(120, 120, 120, 0.8);
  }

  ${isStatic
    ? css`
        opacity: 1;
        background-color: rgba(120, 120, 120, 0.8);
      `
    : ""}
`;
const GraphIconButton = styled(GraphIcon)`
  padding: 1px;
  border-radius: 3px 0 0 3px;
  background-color: #1e2432;
  ${(props) => BaseIconButton(props.isstatic === "true")}
`;
const PieIconButton = styled(PieIcon)`
  border-radius: ${({ rounded }) => (rounded ? "3px" : "0px")};
  ${(props) => BaseIconButton(props.isstatic === "true")}
  ${(props) => BaseIconButton(props.isstatic === "true")}
`;
const ArrowUpIconButton = styled(ArrowUp)`
  border-radius: 0 3px 3px 0;
  ${(props) => BaseIconButton(props.isstatic === "true")}
`;
const ArrowDownIconButton = styled(ArrowDown)`
  border-radius: 0 3px 3px 0;
  ${(props) => BaseIconButton(props.isstatic === "true")}
`;

const SymbolPowerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SymbolContainer = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;
`;
const SymbolLabel = styled.div`
  color: #ffffff;
  font-size: 21px;
  font-weight: bold;
  margin-right: 5px;
`;
const SymbolActionButtons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e2432;
  z-index: 2;
  opacity: 0;
  ${SymbolContainer}:hover & {
    opacity: 1;
  }
`;
const PowerContainer = styled.div`
  width: 32px;
  height: 13px;
  ${(props) => {
    const iconSource = powerIcons[props.showFull ? props.power : "blocked"];
    return css`
      background-image: url(${iconSource});
    `;
  }}
`;
